

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import './navbarleft.css';
import Divider from '@mui/material/Divider';
import { Button, TextField } from '@mui/material';
import { urlBase } from './constants';


const NavScrollOver = ({ headerLinkClick, show, setShowNav, theme }) => {

    const [formMessage, setFormMessage] = useState("");

    const clickAndClose = (i) => {

        setShowNav(false);
        setTimeout(() => {
            headerLinkClick(i);
        }, 300)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // formData.append("_wpcf7_unit_tag", "wpcf7-f3671-p3671-o1");
        // formData.append("_wpcf7", "3671");
        console.log(formData);
        fetch("/wp-json/contact-form-7/v1/contact-forms/3671/feedback", {
            method: "POST",
            body: formData,
        }).then((response) => {
            response.json().then((data) => {
                console.log(data);
                setFormMessage(data.message);
                setTimeout(() => {
                    setFormMessage("");
                }, 50000);
            });
        });
    };

    return (
        <div className={"flex flex-col fixed w-screen left-0 top-0 overflow-scroll z-50 h-screen  bg-black bg-opacity-95 text-white " + (show ? " navslideinleft" : ' hidden navslideoutleft')}>
            <div className="flex mt-4 px-8">
                <div className="ml-auto">
                    <IconButton
                        id="basic-button"
                        forcewhite={1}
                        aria-haspopup="true"
                        onClick={() => setShowNav(false)}
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </div>

            </div>
            <div className="flex flex-col m-auto relative">
                <div className="flex flex-col mx-auto mb-4 mt-2 font-light text-center">
                    <div className="text-3xl my-2 cursor-pointer hover:text-amber-400" onClick={() => clickAndClose(1)}>What We Do</div>
                    <div className="text-3xl my-2 cursor-pointer hover:text-amber-400" onClick={() => clickAndClose(2)}>Work</div>
                    <div className="text-3xl my-2 cursor-pointer hover:text-amber-400" onClick={() => clickAndClose(2.5)}>Strategy</div>
                    <div className="text-3xl my-2 cursor-pointer  hover:text-amber-400" onClick={() => clickAndClose(3)}>Approach</div>
                    <div className="text-3xl my-2 cursor-pointer  hover:text-amber-400" onClick={() => clickAndClose(4)}>Results</div>
                    <div className="text-3xl my-2 cursor-pointer  hover:text-amber-400" onClick={() => clickAndClose(5)}>Clients</div>
                    <div className="text-3xl my-2 cursor-pointer  hover:text-amber-400" onClick={() => clickAndClose(6)}>Team</div>
                    <div className="text-3xl my-2 cursor-pointer hover:text-amber-400 " onClick={() => clickAndClose(7)}>Contact Us</div>
                </div>

                <div className="my-2  max-w-4xl w-full mx-auto">
                    <Divider />
                </div>

                {/* <div className="flex flex-col  mx-auto items-center overflow-hidden">
                    <div className=" text-amber-500 font-light font-18 my-2">Newsletter</div>
                    <div className=" text-white font-light text-custom1 my-2 ">Sign up to our newsletter</div>
                    <form className="flex items-center" onSubmit={handleSubmit}>
                        <div className="mr-4 mb-3">
                            <TextField
                                InputProps={{ darkoverlay: 1, forcewhite: 1 }}
                                InputLabelProps={{ darkoverlay: 1, forcewhite: 1 }}
                                inputProps={{ darkoverlay: 1, forcewhite: 1 }}
                                label="Email"
                                variant="outlined"
                                name="your-email-overlay"
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className="">
                            <Button variant="text" className="text-white" type="submit">Subscribe</Button>

                        </div>
                    </form>


                    {formMessage && <div className="text-white">{formMessage}</div>}
                </div> */}
            </div>

        </div>

    )
}

export const NavBarLeft = ({ headerLinkClick, theme, contentData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (i) => {
        setAnchorEl(null);
        console.log({ i });
        i && headerLinkClick(i);
    };
    const [showNav, setShowNav] = useState(false);

    return (<>
        <div className=" cursor-pointer  hover:bg-opacity-50 lg:flex flex-col fixed left-0 top-0 h-screen justify-center  w-20 z-50 bg-white bg-opacity-40 ">
            <button onClick={() => headerLinkClick(0)} className="mx-auto mt-4 hover:shadow-md p-4" >
                {/* <img className="w-12" src={theme == 'dark' ? `${urlBase}White-DG-Icon.png` : `${urlBase}DG-Color-Icon.png`} alt="Button" /> */}
                <img className="w-12" src={theme == 'dark' ? contentData?.icon_dark : contentData?.icon_light} alt="Button" />
            </button>
            <div className="mt-auto mb-4 mx-auto">
                <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={() => setShowNav(!showNav)}
                >
                    <MenuIcon fontSize="large" />
                </IconButton>
                {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={() => handleClose(1)}>Work</MenuItem>
                <MenuItem onClick={() => handleClose(2)}>Strategy</MenuItem>
                <MenuItem onClick={() => handleClose(3)}>Results</MenuItem>
                <MenuItem onClick={() => handleClose(4)}>Clients</MenuItem>
                <MenuItem onClick={() => handleClose(5)}>Team</MenuItem>
                <MenuItem onClick={() => handleClose(6)}>Contact Us</MenuItem>
            </Menu> */}
            </div>

        </div>
        <NavScrollOver show={showNav} headerLinkClick={headerLinkClick} theme={theme} setShowNav={setShowNav} />
    </>)
}